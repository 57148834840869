import Bridge from './Bridge/Bridge';
import {ConnectionStatus} from './Bridge/ConnectionStatus';
import BackEnd from '../BackEnd/BanckEnd'

enum BridgeStatus{
    STATUS_REGISTERING_CONNECTION                           =0,//Status inicial
    STATUS_CONNECTING                                       =1,//Status usado pelo dispositivo para indicar que ele está se identificando no servidor
    STATUS_CONNECTION_COMPLETED                             =2,//O dispositivo entra nesse status se o mesmo não possuir token de acesso
    STATUS_FAULT_ON_SOCKET                                  =-1,//indica falha na conexão
    STATUS_FAULT_CONNECTION_LOST                            =-2,//indica que uma das parte perdeu conexão
    STATUS_FAULT_TIMEOUT                                    =-3,//indica que em uma das etapas do processo o tempo expirou e a mesma não foi concluida
    STATUS_FAULT_ACCESS_DENIED                              =-4,//indica que o acesso foi negado pelo servidor ou pelo dispositivo
    STATUS_FAULT_DEVICE_NOT_CONNECTED                       =-5,//indica que o dispositivo não está conectado no servidor
    STATUS_FAULT_ON_SERVICE                                 =-6,//indica que o seriço requerido está em falha e não foi possivel conectar
    STATUS_FAULT_INVALID_STATUS                             =-7,//indica que o status recebido não é o esperado
    STATUS_FAULT_INVALID_DATA                               =-8,//indica que os dados recebidos estão corrompidos
    STATUS_FAULT_ON_SERVER                                  =-9,//indica que houve alguma falha no servidor
}


export default class FireBrige {
    private client:WebSocket;
    private deviceId:Number;
    private service:String;    
    public readyState=WebSocket.CONNECTING;
    public onclose: ((this: FireBrige, ev: CloseEvent) => any) | null;
    public onerror: (this: FireBrige, ev: Event) => any=(ev: Event)=>{};
    public onmessage: ((this: WebSocket, ev: MessageEvent) => any) | null;
    public onopen: ((this: FireBrige, ev: Event) => any) | null;

    public callbackStatus:(this:FireBrige,status:BridgeStatus)=>void=(status:BridgeStatus)=>{};

    constructor(deviceId:Number,service:String) {
        this.deviceId=deviceId;
        this.service=service;
    }

   
    async connect(){
        this.callbackStatus(BridgeStatus.STATUS_REGISTERING_CONNECTION);
        let token=(await BackEnd.function().createBridgeAccess(this.deviceId,this.service)).token;//JSON.stringify({deviceId:this.deviceId,service:this.service});
        this.callbackStatus(BridgeStatus.STATUS_CONNECTING);
        console.log(token);
        if(token){
            this.client=new WebSocket("wss://bridge.firebee.com.br/bridge/v1/client");
            this.client.binaryType='arraybuffer';
            this.client.onopen=()=>{
                var bridge=new Bridge(this.client,token,()=>{//OnInterconnect
                    this.callbackStatus(BridgeStatus.STATUS_CONNECTION_COMPLETED);
                    this.readyState=WebSocket.OPEN;
                    this.onmessage;
                    this.client.onmessage = this.onmessage;
                    this.onopen(null);                   
                },
                (status)=>{//OnConnectionLost
                    this.readyState=WebSocket.CLOSED;
                    let fault;
                    switch(status){
                        case ConnectionStatus.STATUS_FAULT_ON_SOCKET:
                            this.callbackStatus(BridgeStatus.STATUS_FAULT_ON_SOCKET);
                            fault="Falha no socket";
                        break;
                        case ConnectionStatus.STATUS_FAULT_CONNECTION_LOST:
                            this.callbackStatus(BridgeStatus.STATUS_FAULT_CONNECTION_LOST);
                            fault="Conexão Perdida";
                            break;
                        case ConnectionStatus.STATUS_FAULT_TIMEOUT:
                            this.callbackStatus(BridgeStatus.STATUS_FAULT_TIMEOUT);
                            fault="Timeout";
                            break;
                        case ConnectionStatus.STATUS_FAULT_ACCESS_DENIED:
                            this.callbackStatus(BridgeStatus.STATUS_FAULT_ACCESS_DENIED);
                            fault="Acesso negado";
                            break;
                        case ConnectionStatus.STATUS_FAULT_DEVICE_NOT_CONNECTED:
                            this.callbackStatus(BridgeStatus.STATUS_FAULT_DEVICE_NOT_CONNECTED);
                            fault="Dispositivo offline";
                            break;
                        case ConnectionStatus.STATUS_FAULT_ON_SERVICE:
                            this.callbackStatus(BridgeStatus.STATUS_FAULT_ON_SERVICE);
                            fault="Falha no serviço";
                            break;
                        case ConnectionStatus.STATUS_FAULT_INVALID_STATUS:
                            this.callbackStatus(BridgeStatus.STATUS_FAULT_INVALID_STATUS);
                            fault="Estado inesperado";
                            break;
                        case ConnectionStatus.STATUS_FAULT_INVALID_DATA:
                            this.callbackStatus(BridgeStatus.STATUS_FAULT_INVALID_DATA);
                            fault="Dados corrompidos";
                            break;
                        case ConnectionStatus.STATUS_FAULT_ON_SERVER:
                            this.callbackStatus(BridgeStatus.STATUS_FAULT_ON_SERVER);
                            fault="Falha no servidor";
                            break;
                        default:
                            fault="Motivo desconhecido";
                            break;
                    }
                    this.onclose(fault);
                });
            }
        }
    }

    close(code?: number, reason?: string):void{
        this.client.close(code,reason);
    }

    send(data: string | ArrayBufferLike | Blob | ArrayBufferView):void{
        //console.log("Send data: "+data)
        this.client.send(data);
    }

}

export {FireBrige,BridgeStatus};